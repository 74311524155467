import React from "react";
import { Link } from "gatsby";
import {css} from '@emotion/core';

import Layout, {bp} from '../../components/layout';
import SubCover from '../../components/sub-cover';
import PageContent from '../../components/page-content';

const createBanner = (title, image) => (
  <Link to={`/technology/${title}`} key={title}>
    <div
      css={css`
        width: calc(100% - 35px);
        height: 75px;
        &:hover {
          opacity: 0.8;
        }
        ${bp.xs} {
          height: 125px;
        }
        ${bp.sm} {
          height: 175px;
        }
      `}
    >
      {/* <OverlayGreen css={css`
          height: 150px;
          ${bp.xs} {
            height: 250px;
          }
          ${bp.sm} {
            height: 350px;
          }
        `}/> */}
      <div
        css={css`
          position: absolute;
          width: calc(100% - 35px);
          color: white;
          font-weight: 600;
          font-size: 25px;
          text-align: center;
          line-height: 75px;
          background-position: center top;
          background-size: cover;
          ${bp.xs} {
            font-size: 50px;
            line-height: 125px;
          }
          ${bp.sm} {
            font-size: 75px;
            line-height: 175px;
          }
        `}
        style={{ backgroundImage: `url(${image})` }}
      >
        {title}
      </div>
    </div>
  </Link>
);

const createCaption = child => (
  <div
    css={css`
      margin: -10px 0 20px 0;
    `}
  >
    {child}
  </div>
);

const createSolution = (name, title, caption, image, url) => (
  <>
    {
      url ? (
        <a href={url} rel="noopener noreferrer" target="_blank"><h4>{title}</h4></a>
      ) : (
        <Link to={`/technology/${name}`}>
          <h4>{title}</h4>
        </Link>
      )
    }
    {createCaption(caption)}
    {image && createBanner(name, image)}
  </>
);

export default () => (
  <Layout pageTitle="開発・制作">
    <SubCover
      title="製品・技術"
      caption=""
      image="technology-cover.jpg"
    />

    <div css={css`
      margin-top: 0px;
    `}>
        
      <PageContent>
      {createSolution(
          'soundtope',
          'soundtope（サウンドトープ）',
          <>
            音／音楽のライブデザイン　あなたの今につながる<br />
            《自動音楽生成ソリューション》
          </>,
          '/soundtope-cover.jpg'
        )}

        {createSolution(
          'omnion',
          'omnion（オムニオン）',
          <>
            音で空間をつつみこむ<br />
           《空間音響デザイン》
          </>,
          '/omnion-cover.jpg'
        )}

        {createSolution(
          'sonicwalk',
          'sonicwalk（ソニックウォーク）',
          <>
            街を楽器に変える<br />
            《位置連動音楽体験システム》
          </>,
          '/sonicwalk-cover.jpg'
        )}

        {createSolution(
          'mimicafe',
          'mimicafe（ミミカフェ）',
          <>
            soundtopeをアプリで体験<br />
            《カフェノイズ生成アプリ》
          </>,
          '/mimicafe-cover.jpg'
        )}

        {createSolution(
          'beatcamera',
          'beatcamera（ビートカメラ）',
          <>
            誰でもおもしろ動画が作れる動画編集アプリ
          </>,
          null,
          'https://beatcamera.com/'
        )}

        {createSolution(
          'others',
          'その他の技術',
          <>
          </>,
          null
        )}

      </PageContent>
      
    </div>

  </Layout>
);
